@import "./shared";
@import "./carousel";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: $paper;
  color: $dark;
  overscroll-behavior: none;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.hyperLink {
  font-weight: bold;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  
  li {
    margin-left: 20px;

    &::marker {
      color: $primary;
      content: '► ';
    }
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.wide {
  width: 100%;
  max-width: 100vw;
}

.content {
  max-width: $max-content-width;
}

.narrow {
  max-width: $max-narrow-content-width;
}

.fullHeight {
  min-height: calc(100vh - $header-height-mobile);
  @include on-desktop-with-height {
    min-height: calc(100vh - $header-height);
  }
}

section {
  margin: 0 auto;
  padding: 0 24px;
  max-width: $max-content-width;
  @include on-larger-content {
    padding: 0;
  }
}

.dark {
  background-color: $dark;
  color: $white;
}

.light {
  background-color: $paper;
  color: $dark;
}

/* specific to slices */

/* for two TitleText slices after each other
 * remove the duplicated padding
 */

.titleTextSlice.dark + .titleTextSlice:not(.light),
.titleTextSlice.light + .titleTextSlice:not(.dark) {
  section {
    padding-top: 0;
  }
}

p.MuiTypography-root + p.MuiTypography-root {
  margin-top: 28px;
}

p:empty.MuiTypography-root + p:empty.MuiTypography-root {
  margin-top: 28px;
  padding-block: 0.05px;
}

p {
  white-space: pre-wrap;
}
