@import './shared';

.carousel {
  &.dark {
    background-color: $dark;
  }

  ul {
    li::marker {
      content: none;
    }
  }

  .splide__arrows {
    position: absolute;
    bottom: 0;
    left: 0;

    .splide__arrow {
      border-radius: 0%;
      opacity: 1;
      position: relative;
      transform: translateY(0);

      &--prev {
        left: 0;

        svg {
          transform: scaleX(1);
        }
      }

      &--next {
        right: 0;
      }
    }
  }

  .splide__pagination--custom {
    position: static;

    .splide__pagination__page {
      width: 14px;
      height: 14px;
      background-color: $primary-light2;

      &.is-active {
        background-color: $primary;
        transform: scale(1);
      }
    }
  }
}
